<template>
	<v-row>
		<v-col>
			<CountEventCard />
		</v-col>

		<v-col cols="4">
			<LastNews />
		</v-col>
	</v-row>
</template>

<script>
	import LastNews from '@/components/LastNews';
	import CountEventCard from '@/components/CountEventCard';

	export default {
		name: 'dashboard-administration',
		components: {
			LastNews,
			CountEventCard,
		},
	};
</script>
