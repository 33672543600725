<template>
	<v-card class="mx-auto">
		<v-img height="70px" :src="require('../assets/header-news.jpeg')">
			<v-card-title class="white--text">
				Noticias recientes
			</v-card-title>
		</v-img>

		<v-list-item
			v-for="item in lastnews"
			three-line
			:key="item.id"
			@click="openLink(item.link)"
		>
			<v-list-item-content>
				<v-list-item-title>{{ item.title.rendered }}</v-list-item-title>
				<v-list-item-subtitle
					v-html="item.excerpt.rendered"
				></v-list-item-subtitle>
			</v-list-item-content>
		</v-list-item>
	</v-card>
</template>

<script>
	import axios from 'axios';

	export default {
		name: 'last-news',
		data() {
			return {
				lastnews: [],
				images: [],
			};
		},
		mounted() {
			// https://developer.wordpress.org/rest-api/reference/posts/#list-posts
			axios
				.get(
					'https://www.canalmaule.cl/wp-json/wp/v2/posts?per_page=5&categories=25,13'
				)
				.then(response => (this.lastnews = response.data));
		},
		methods: {
			openLink(url) {
				var win = window.open(url, '_blank');
				win.focus();
			},
		},
	};
</script>
